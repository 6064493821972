var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header-revista"},[_c('header',{staticClass:"header-revista-conteudo"},[_c('div',{staticClass:"revista-logo"},[_c('router-link',{attrs:{"to":"/"}},[_c('img',{attrs:{"src":require("./../assets/novas_imgs/new_logo_revista.svg"),"loading":"lazy"}})])],1),_c('div',{staticClass:"revista-header-btns"},[_c('router-link',{attrs:{"to":"/"}},[_vm._v("Home")]),_c('router-link',{attrs:{"to":{
					name: 'materia',
					params: { materia: 'CAPA' }
				}}},[_vm._v("Revista Atual")]),_c('a',{attrs:{"href":"#magazines"}},[_vm._v("Revistas Anteriores")])],1),_c('div',{staticClass:"btns-acesso"},[_c('router-link',{staticClass:"receba-link",attrs:{"to":"/login"}},[_vm._v("Receba o Link de Acesso")]),_vm._m(0)],1)]),_c('Slide',[_c('router-link',{attrs:{"to":"/"}},[_vm._v("Home")]),_c('router-link',{attrs:{"to":"/login"}},[_vm._v("Receba o Link de Acesso")]),_c('a',{attrs:{"href":"#magazines"}},[_vm._v("Revista Anteriores")]),_c('router-link',{attrs:{"to":{
				name: 'materia',
				params: { materia: 'CAPA' }
			}}},[_vm._v("Revista Atual")]),_c('a',{attrs:{"href":"#planos"}},[_vm._v("Assinar")])],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"revista-assinar-btn",attrs:{"href":"#planos"}},[_c('div',{staticClass:"revista-assinar-btn"},[_c('a',{attrs:{"href":"#planos"}},[_vm._v("Assinar")])])])
}]

export { render, staticRenderFns }