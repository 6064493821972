import { render, staticRenderFns } from "./NewArtigos.vue?vue&type=template&id=76c5f7ce&scoped=true"
import script from "./NewArtigos.vue?vue&type=script&lang=js"
export * from "./NewArtigos.vue?vue&type=script&lang=js"
import style0 from "./NewArtigos.vue?vue&type=style&index=0&id=76c5f7ce&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76c5f7ce",
  null
  
)

export default component.exports