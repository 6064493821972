<template>
	<div class="body-revista" id="atual">
		<div class="secao-btn-mais">
			<div class="ver-mais">
				<a href="#atual">
					<p>Ver Mais</p>
					<img
						src="./../assets/novas_imgs/seta_baixo.svg"
						loading="lazy"
					/>
				</a>
			</div>
		</div>
		<div class="secao-revista-materia">
			<div class="titulo-revista-materia">
				<h3>EDIÇÃO 80</h3>
				<p>Matéria de Capa</p>
			</div>
			<div class="revista-materia">
				<h2>
					Compro, logo existo
				</h2>
			</div>
		</div>

		<div class="revistas-materias">
			<div
				class="card-materia"
				v-for="conteudo of conteudoMateria"
				:key="conteudo.titulo"
				@mouseover="conteudo.viewModal = true"
				@mouseleave="conteudo.viewModal = false"
			>
				<div>
					<div class="titulo">
						<h2>{{ conteudo.titulo }}</h2>
					</div>
					<div class="informacao">
						<p>
							{{ conteudo.resumo }}
						</p>
					</div>
					<div class="info-autor">
						<div class="img-autor">
							<img :src="conteudo.imgAutor" loading="lazy" />
						</div>
						<div class="dados-autor">
							<h4>Por {{ conteudo.nomeAutor }}</h4>
							<p>{{ conteudo.cargoAutor }}</p>
						</div>
						<div class="btns-mobile">
							<router-link
								:to="{
									name: 'materia',
									params: { materia: 'CAPA' }
								}"
								class="modal-preview"
							>
								Ver Preview
							</router-link>
							<router-link to="/login">
								Já Sou Assinante
							</router-link>
						</div>
					</div>
				</div>
				<div v-show="conteudo.viewModal" class="modal-revista-atual">
					<router-link
						:to="{
							name: 'materia',
							params: { materia: conteudo.titulo }
						}"
						class="modal-preview"
					>
						<p>LER PREVIEW</p>
					</router-link>

					<router-link to="/login">
						<p class="assinante-modal">
							ler completo, já sou assinante
						</p>
					</router-link>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import artigos from '../store/artigos';
export default {
	data() {
		return {
			active: false,
			conteudoMateria: [...artigos.materia]
		}
	}
}
</script>

<style scoped>
.body-revista {
	display: flex;
	position: relative;
	flex-direction: column;
	width: 85%;
	margin: 0px auto;
}

.body-revista .secao-btn-mais {
	display: flex;
	position: relative;
	justify-content: center;
	align-items: flex-start;
	height: 165px;
}

.body-revista {
	display: flex;
	position: relative;
	justify-content: center;
	align-items: center;
}

.body-revista .ver-mais {
	display: flex;
	align-items: center;
	flex-direction: column;
	margin-top: 19px;
}

.body-revista .ver-mais a {
	display: flex;
	align-items: center;
	flex-direction: column;
	text-decoration: none;
}

.body-revista .ver-mais p {
	text-transform: uppercase;
	color: #000;
	font-weight: bold;
	letter-spacing: 0px;
	font-size: 15px;
	text-align: center;
	margin-bottom: 3px;
}

.secao-revista-materia {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	max-width: 1619px;
}

.titulo-revista-materia {
	display: flex;
	align-items: flex-end;
	width: 16%;
	justify-content: space-around;
	text-transform: uppercase;
	top: 5px;
	position: relative;
	background-color: #fff;
	min-width: 245px;
}

.titulo-revista-materia h3 {
	margin: 0px;
	color: #000;
	font-size: 35px;
}

.titulo-revista-materia p {
	margin: 0px;
	color: #808080;
	font-size: 20px;
}

.revista-materia {
	border-top: 3px solid #000;
	border-bottom: 3px solid #000;
	width: 100%;
	max-width: 1619px;
	margin: 0px auto;
}

.revista-materia h2 {
	font-size: 67px;
	font-weight: 500;
	text-transform: uppercase;
	margin: 35px 0px;
	letter-spacing: -1px;
	text-align: center;
}

.revistas-materias {
	display: flex;
	width: 100%;
	margin: 60px auto;
	max-width: 1619px;
}

.revistas-materias .card-materia {
	display: flex;
	flex-direction: column;
	width: 32%;
	margin: 5px 10px;
}

.revistas-materias .card-materia .titulo h2 {
	color: #808080;
	text-transform: uppercase;
	letter-spacing: -2px;
	font-size: 27px;
	margin-left: 10px;
	letter-spacing: 0.5px;
}

.revistas-materias .card-materia .informacao {
	border-bottom: 3px solid #000;
	border-top: 3px solid #000;
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 261px;
}

.revistas-materias .card-materia .informacao p {
	color: #000;
	text-transform: uppercase;
	font-size: 32px;
	margin: 25px auto;
	width: 90%;
	letter-spacing: 0.2px;
	line-height: 41px;
}

.revistas-materias .card-materia .info-autor {
	display: flex;
	margin: 15px 0px 0px 15px;
}

.revistas-materias .card-materia .info-autor .dados-autor {
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-left: 15px;
}

.revistas-materias .card-materia .info-autor h4 {
	margin: 0px;
	font-size: 15px;
	font-weight: bold;
}

.revistas-materias .card-materia .info-autor p {
	margin: 0px;
	font-size: 12px;
}

.destaque {
	width: 100%;
	border-top: 2px solid;
	border-bottom: 2px solid;
	margin-bottom: 45px;
}

.destaque p {
	font-size: 35px;
	text-transform: uppercase;
	text-align: center;
	-webkit-text-stroke-width: 0.5px;
	font-weight: 700;
	background: linear-gradient(90deg, #000, #fff, #000);
	letter-spacing: 5px;
	-webkit-background-clip: text;
	background-clip: text;
	-webkit-text-fill-color: transparent;
	background-repeat: no-repeat;
	background-size: 80%;
	animation: shine 5s linear infinite;
	position: relative;
	margin-bottom: 0px;
}

@keyframes shine {
	0% {
		background-position-x: -500%;
	}

	100% {
		background-position-x: 500%;
	}
}

.destaque img {
	width: 100%;
}

.btns-mobile {
	display: none;
}

.img-autor img {
	border-radius: 45px;
	width: 34px;
	height: 34px;
}

.modal-revista-atual {
	display: flex;
	flex-direction: column;
	align-items: center;
	border: 2px solid;
	position: absolute;
	width: 32%;
	height: 37%;
	justify-content: flex-end;
	background-color: hsl(0deg 0% 100% / 95%);
}

.modal-revista-atual a {
	color: #000;
	text-transform: uppercase;
}

.modal-revista-atual .modal-preview {
	text-decoration: none;
	font-size: 35px;
	font-weight: bold;
	letter-spacing: 8px;
	margin-bottom: 3em;
}

@media (max-width: 940px) {
	.modal-revista-atual {
		display: none;
	}

	.titulo-revista-materia {
		display: block;
		flex-direction: column;
		align-items: end;
		width: 64%;
		background-color: transparent;
	}

	.titulo-revista-materia p {
		font-size: 16px;
		margin-top: 10px;
		background-color: #fff;
		width: 100px;
	}

	.revistas-materias {
		flex-direction: column;
	}

	.revistas-materias .card-materia {
		width: 100%;
		margin: 30px 10px;
	}

	.btns-mobile {
		display: flex;
		width: 100%;
		justify-content: space-around;
		margin-top: 30px;
	}

	.btns-mobile a {
		background-color: #000;
		height: 60px;
		width: 45%;
		color: #fff;
		display: flex;
		align-items: center;
		justify-content: center;
		text-transform: uppercase;
		font-size: 12px;
	}

	.revistas-materias .card-materia .info-autor {
		flex-wrap: wrap;
		margin: 10px 0px;
	}

	.revistas-materias .card-materia .informacao p {
		font-size: 30px;
	}

	.revista-materia h2 {
		font-size: 36px;
	}

	.titulo-revista-materia h3 {
		font-size: 26px;
	}

	.destaque p {
		font-size: 34px;
		-webkit-text-stroke-width: 1px;
	}
}
</style>
