var revistas = [
  {
    id: 81,
    edicao: "Edição 81",
    titulo: "A confeitaria do e-commerce",
    subTitulo:
      "As mudanças nas relações de consumo na era digital",
    linkRevista: "",
    hover: false,
    backgroundCapa: require("./assets/imgs/imgs_81/1920x793px.png"),
    imgCapaRevista: require("./assets/imgs/imgs_81/332x454px.png"),
    imgBackgroundLeft: require("./assets/imgs/imgs_81/1185x407px.png"),
    imgDestaqueLeft: require("./assets/imgs/imgs_81/430x590px.png"),
    imgRevistaExibe: require("./assets/imgs/imgs_81/500x500px-CAPA.png"),
  },
  {
    id: 80,
    edicao: "Edição 80",
    titulo: "Compro, logo existo",
    subTitulo:
      "As mudanças nas relações de consumo na era digital",
    linkRevista: "https://e.issuu.com/embed.html?d=revista-ecbr-ed-080-compro-logo-existo&hideIssuuLogo=true&hideShareButton=true&showOtherPublicationsAsSuggestions=true&u=ecommercebrasil",
    hover: false,
    backgroundCapa: require("./assets/imgs/imgs_80/1920x793px.png"),
    imgCapaRevista: require("./assets/imgs/imgs_80/332x454px.png"),
    imgBackgroundLeft: require("./assets/imgs/imgs_80/1185x407px.png"),
    imgDestaqueLeft: require("./assets/imgs/imgs_80/430x590px.png"),
    imgRevistaExibe: require("./assets/imgs/imgs_80/500x500px-CAPA.png"),
  },
  {
    id: 79,
    edicao: "Edição 79",
    titulo: "Moda e sustentabilidade em pauta: consumidor no centro",
    subTitulo:
      "Pensar em moda é ter o consimidor no centro",
    linkRevista: "https://e.issuu.com/embed.html?d=revista-ecbr-ed-079-cada-vez-mais-sustentavel&hideIssuuLogo=true&hideShareButton=true&showOtherPublicationsAsSuggestions=true&u=ecommercebrasil",
    hover: false,
    backgroundCapa: require("./assets/imgs/imgs_79/1920x793px.png"),
    imgCapaRevista: require("./assets/imgs/imgs_79/332x454px.png"),
    imgBackgroundLeft: require("./assets/imgs/imgs_79/1185x407px.png"),
    imgDestaqueLeft: require("./assets/imgs/imgs_79/430x590px.png"),
    imgRevistaExibe: require("./assets/imgs/imgs_79/500x500px-CAPA.png"),
  },
  {
    id: 78,
    edicao: "Edição 78",
    titulo: "Caos, maturidade e criação",
    subTitulo:
      "O mercado está mudando (mas estamos de olho por você).",
    linkRevista: "https://e.issuu.com/embed.html?d=revista_e-commerce_brasil_78_tendencias_2024&hideIssuuLogo=true&hideShareButton=true&showOtherPublicationsAsSuggestions=true&u=ecommercebrasil",
    hover: false,
    backgroundCapa: require("./assets/imgs/imgs_78/1920x793px.jpg"),
    imgCapaRevista: require("./assets/imgs/imgs_78/332x454px.jpg"),
    imgBackgroundLeft: require("./assets/imgs/imgs_78/1185x407px.jpg"),
    imgDestaqueLeft: require("./assets/imgs/imgs_78/430x590px.jpg"),
    imgRevistaExibe: require("./assets/imgs/imgs_78/500x500px-CAPA.jpg"),
  },
  {
    id: 77,
    edicao: "Edição 77",
    titulo: "IA NO E-COMMERCE",
    subTitulo:
      "A inteligência artificial representa um divisor de águas no varejo, que deve aprender a abraçar a nova tecnologia e se reinventar diante das oportunidades.",
    linkRevista: "https://e.issuu.com/embed.html?d=revista-ecommerce-brasil-edicao-77&hideIssuuLogo=true&hideShareButton=true&showOtherPublicationsAsSuggestions=true&u=ecommercebrasil",
    hover: false,
    backgroundCapa: require("./assets/imgs/imgs_77/1920x793px.png"),
    imgCapaRevista: require("./assets/imgs/imgs_77/332x454px.png"),
    imgBackgroundLeft: require("./assets/imgs/imgs_77/1185x407px.png"),
    imgDestaqueLeft: require("./assets/imgs/imgs_77/430x590px.png"),
    imgRevistaExibe: require("./assets/imgs/imgs_77/500x500px-CAPA.png"),
  },
  {
    id: 76,
    edicao: "Edição 76",
    titulo: "DE VOLTA PARA O FUTURO",
    subTitulo:
      "o vintage nunca foi embora, mas está cada vez mais presente na compras online",
    linkRevista:
      "https://e.issuu.com/embed.html?d=revista_e-commerce_brasil_76_de_volta_para_o_futur&hideIssuuLogo=true&hideShareButton=true&showOtherPublicationsAsSuggestions=true&u=ecommercebrasil",
    hover: false,
    backgroundCapa: require("./assets/imgs/imgs_76/1920x793px.png"),
    imgCapaRevista: require("./assets/imgs/imgs_76/332x454px.png"),
    imgBackgroundLeft: require("./assets/imgs/imgs_76/1185x407px.png"),
    imgDestaqueLeft: require("./assets/imgs/imgs_76/430x590px.png"),
    imgRevistaExibe: require("./assets/imgs/imgs_76/500x500px-CAPA.png"),
  },
  {
    id: 75,
    edicao: "EDIÇÃO 75",
    titulo: "OS CAÇULAS DO E-COMMERCE: COMO CONQUISTAR A GERAÇÃO Z",
    subTitulo:
      "Sim, a geração Z está chegando aos poucos à maioridade, e muitos desses jovens adultos já trabalham e gastam o próprio dinheiro no e-commerce.",
    linkRevista:
      "https://e.issuu.com/embed.html?d=revista_e-commerce_brasil_75_gera_o_z&hideIssuuLogo=true&hideShareButton=true&showOtherPublicationsAsSuggestions=true&u=ecommercebrasil",
    hover: false,
    backgroundCapa: require("./assets/imgs/imgs_75/1920x793px.png"),
    imgCapaRevista: require("./assets/imgs/imgs_75/332x454px.png"),
    imgBackgroundLeft: require("./assets/imgs/imgs_75/1185x407px.png"),
    imgDestaqueLeft: require("./assets/imgs/imgs_75/430x590px.png"),
    imgRevistaExibe: require("./assets/imgs/imgs_75/500x500px-CAPA.png"),
  },
  {
    id: 74,
    edicao: "Edição 74",
    titulo: "ChatGPT e e-commerce dão match?",
    subTitulo:
      "Como a ferramenta de AI pode impactar o setor sem prejudicá-lo?",
    linkRevista:
      "https://e.issuu.com/embed.html?d=revista_e-commerce_brasil_74_chatgpt_e_e-commerce&u=ecommercebrasil&hideIssuuLogo=true&hideShareButton=true&showOtherPublicationsAsSuggestions=true&u=ecommercebrasil",
    hover: false,
    backgroundCapa: require("./assets/imgs/imgs_74/1920x793px.png"),
    imgCapaRevista: require("./assets/imgs/imgs_74/332x454px.png"),
    imgBackgroundLeft: require("./assets/imgs/imgs_74/1185x407px.png"),
    imgDestaqueLeft: require("./assets/imgs/imgs_74/430x590px.png"),
    imgRevistaExibe: require("./assets/imgs/imgs_74/500x500px-CAPA.png"),
  },
  {
    id: 73,
    edicao: "Edição 73",
    titulo: "E-Commerce Automotivo",
    subTitulo: 'O que falta para o modelo "pegar" e conectar clientes ?',
    linkRevista:
      "//e.issuu.com/embed.html?d=revista_e-commerce_brasil_73_e-commerce_automotivo&u=ecommercebrasil&hideIssuuLogo=true&hideShareButton=true&showOtherPublicationsAsSuggestions=true&u=ecommercebrasil",
    hover: false,
    backgroundCapa: require("./assets/imgs/imgs_73/1920x793px.png"),
    imgCapaRevista: require("./assets/imgs/imgs_73/332x454px.png"),
    //backgroundCapaRevista: require('./assets/imgs_67/313x442px.png'),
    imgBackgroundLeft: require("./assets/imgs/imgs_73/1185x407px.png"),
    imgDestaqueLeft: require("./assets/imgs/imgs_73/430x590px.png"),
    imgRevistaExibe: require("./assets/imgs/imgs_73/500x500px-CAPA.png"),
  },
  {
    id: 72,
    edicao: "Edição 72",
    titulo: "O que esperar do e-commerce em 2023?",
    subTitulo:
      "Especialistas analisam a evolução do setor e as expectativas para o próximo ano",
    linkRevista:
      "//e.issuu.com/embed.html?d=revista_e-commerce_brasil_72_o_que_esperar_de_2023&u=ecommercebrasil&hideIssuuLogo=true&hideShareButton=true&showOtherPublicationsAsSuggestions=true&u=ecommercebrasil",
    hover: false,
    backgroundCapa: require("./assets/imgs/imgs_72/1920x793px.png"),
    imgCapaRevista: require("./assets/imgs/imgs_72/332x454px.png"),
    //backgroundCapaRevista: require('./assets/imgs_67/313x442px.png'),
    imgBackgroundLeft: require("./assets/imgs/imgs_72/1185x407px.png"),
    imgDestaqueLeft: require("./assets/imgs/imgs_72/430x590px.png"),
    imgRevistaExibe: require("./assets/imgs/imgs_72/500x500px-CAPA.png"),
  },
  {
    id: 71,
    edicao: "Edição 71",
    titulo: "Logística além das máquinas",
    subTitulo:
      "Artistas indígenas usam e-commerce para vender seus produtos para todo o Brasil",
    linkRevista:
      "//e.issuu.com/embed.html?d=revista_e-commerce_brasil_71_logistica_em_foco&u=ecommercebrasil&hideIssuuLogo=true&hideShareButton=true&showOtherPublicationsAsSuggestions=true&u=ecommercebrasil",
    hover: false,
    backgroundCapa: require("./assets/imgs/imgs_71/1920x793px.png"),
    imgCapaRevista: require("./assets/imgs/imgs_71/332x454px.png"),
    //backgroundCapaRevista: require('./assets/imgs_67/313x442px.png'),
    imgBackgroundLeft: require("./assets/imgs/imgs_71/1185x407px.png"),
    imgDestaqueLeft: require("./assets/imgs/imgs_71/430x590px.png"),
    imgRevistaExibe: require("./assets/imgs/imgs_71/500x500px-CAPA.png"),
  },
  {
    id: 70,
    edicao: "Edição 70",
    titulo: "A Arte Indígena no e-commerce",
    subTitulo:
      "Artistas indígenas usam e-commerce para vender seus produtos para todo o Brasil",
    linkRevista:
      "//e.issuu.com/embed.html?d=revista_ecb_70-conectando-culturas&u=ecommercebrasil&hideIssuuLogo=true&hideShareButton=true&showOtherPublicationsAsSuggestions=true&u=ecommercebrasil",
    hover: false,
    backgroundCapa: require("./assets/imgs/imgs_70/1920x793px.png"),
    imgCapaRevista: require("./assets/imgs/imgs_70/332x454px.png"),
    //backgroundCapaRevista: require('./assets/imgs_67/313x442px.png'),
    imgBackgroundLeft: require("./assets/imgs/imgs_70/1185x407px.png"),
    imgDestaqueLeft: require("./assets/imgs/imgs_70/430x590px.png"),
    imgRevistaExibe: require("./assets/imgs/imgs_70/500x500px-CAPA.png"),
  },
  {
    id: 69,
    edicao: "Edição 69",
    titulo: "É preciso saber como se fazer presente",
    subTitulo: "O mundommudou e o marketing também.",
    linkRevista:
      "//e.issuu.com/embed.html?d=revista_ecb_69-comunica_o-l_quida&u=ecommercebrasil&hideIssuuLogo=true&hideShareButton=true&showOtherPublicationsAsSuggestions=true&u=ecommercebrasil",
    hover: false,
    backgroundCapa: require("./assets/imgs/imgs_69/1920x793px.png"),
    imgCapaRevista: require("./assets/imgs/imgs_69/332x454px.png"),
    //backgroundCapaRevista: require('./assets/imgs_67/313x442px.png'),
    imgBackgroundLeft: require("./assets/imgs/imgs_69/1185x407px.png"),
    imgDestaqueLeft: require("./assets/imgs/imgs_69/430x590px.png"),
    imgRevistaExibe: require("./assets/imgs/imgs_69/500x500px-CAPA.png"),
  },
  {
    id: 68,
    edicao: "Edição 68",
    titulo: "Black Money no E-commerce",
    subTitulo: "O que o varejo está fazendo para abraçar esse movimento?",
    linkRevista:
      "//e.issuu.com/embed.html?d=revista-ecommercebrasil-ed68&u=ecommercebrasil&hideIssuuLogo=true&hideShareButton=true&showOtherPublicationsAsSuggestions=true&u=ecommercebrasil",
    hover: false,
    backgroundCapa: require("./assets/imgs/imgs_68/1920x793px.jpg"),
    imgCapaRevista: require("./assets/imgs/imgs_68/332x454px.png"),
    //backgroundCapaRevista: require('./assets/imgs_67/313x442px.png'),
    imgBackgroundLeft: require("./assets/imgs/imgs_68/1185x407px.png"),
    imgDestaqueLeft: require("./assets/imgs/imgs_68/430x590px.png"),
    imgRevistaExibe: require("./assets/imgs/imgs_68/500x500px.png"),
  },
  {
    id: 67,
    edicao: "Edição 67",
    titulo: "O futuro é agora",
    subTitulo: "Estamos vivendo as tendências previstas para o e-commerce",
    linkRevista:
      "//e.issuu.com/embed.html?d=revista_ecb_67&u=ecommercebrasil&hideIssuuLogo=true&hideShareButton=true&showOtherPublicationsAsSuggestions=true&u=ecommercebrasil",
    hover: false,
    backgroundCapa: require("./assets/imgs/imgs_67/1920x793px.png"),
    imgCapaRevista: require("./assets/imgs/imgs_67/332x454px.png"),
    //backgroundCapaRevista: require('./assets/imgs_67/313x442px.png'),
    imgBackgroundLeft: require("./assets/imgs/imgs_67/1185x407px.png"),
    imgDestaqueLeft: require("./assets/imgs/imgs_67/430x590px.png"),
    imgRevistaExibe: require("./assets/imgs/imgs_67/500x500px.png"),
  },
  {
    id: 66,
    edicao: "Edição 66",
    titulo: "Crise energética na China",
    subTitulo: "Como isso afeta o e-commerce no Brasil",
    linkRevista:
      "https://e.issuu.com/embed.html?d=revista_ecb_66_china&u=ecommercebrasil&hideIssuuLogo=true&hideShareButton=true&showOtherPublicationsAsSuggestions=true&u=ecommercebrasil",
    hover: false,
    backgroundCapa: require("./assets/imgs/imgs_66/1920x793px.jpg"),
    imgCapaRevista: require("./assets/imgs/imgs_66/332x454px.jpg"),
    backgroundCapaRevista: require("./assets/imgs/imgs_66/313x442px.jpg"),
    imgBackgroundLeft: require("./assets/imgs/imgs_66/1185x407px.jpg"),
    imgDestaqueLeft: require("./assets/imgs/imgs_66/430x590px.jpg"),
    imgRevistaExibe: require("./assets/imgs/imgs_66/486x486px-ed66.png"),
  },
  {
    id: 65,
    edicao: "Edição 65",
    titulo: "A Corrida do E-Commerce",
    subTitulo:
      "Grandes players disputam quem entrega mais rápido ao consumidor",
    linkRevista:
      "https://e.issuu.com/embed.html?d=revista_ecb_65-site&u=ecommercebrasil&hideIssuuLogo=true&hideShareButton=true&showOtherPublicationsAsSuggestions=true&u=ecommercebrasil",
    hover: false,
    backgroundCapa: require("./assets/imgs/imgs_65/FUNDO_CAPA-1920x793px.png"),
    imgCapaRevista: require("./assets/imgs/imgs_65/CAPA-PEQUENA_REVISTA-332x454px.png"),
    backgroundCapaRevista: require("./assets/imgs/imgs_65/FUNDO_TITULO_313x442px.png"),
    imgBackgroundLeft: require("./assets/imgs/imgs_65/IMG_1185X407-03.png"),
    imgDestaqueLeft: require("./assets/imgs/imgs_65/DESTAQUE-430x590px.png"),
    imgRevistaExibe: require("./assets/imgs/imgs_65/thumb_65.jpg"),
  },
  {
    id: 64,
    edicao: "Edição 64",
    titulo: "Estratégia de sobrevivência",
    subTitulo: "O CRM e o relacionamento cada vez mais proximo com seu cliente",
    linkRevista:
      "//e.issuu.com/embed.html?d=revista_ecb_64_sem_marca_de_corte&hideIssuuLogo=true&showOtherPublicationsAsSuggestions=true&u=ecommercebrasil&hideShareButton=true&pageNumber=1",
    hover: false,
    backgroundCapa: require("./assets/imgs/imgs_64/FUNDO_CAPA-1920x793px.png"),
    imgCapaRevista: require("./assets/imgs/imgs_64/64.png"),
    backgroundCapaRevista: require("./assets/imgs/imgs_64/FUNDO_TITULO_313x442px.png"),
    imgBackgroundLeft: require("./assets/imgs/imgs_64/IMG_1185X407-03.jpg"),
    imgDestaqueLeft: require("./assets/imgs/imgs_64/IMG_DESTAQUE_430x590px.jpg"),
    imgRevistaExibe: require("./assets/imgs/imgs_64/tumb_64.png"),
  },
  {
    id: 63,
    edicao: "Edição 63",
    titulo: "E-commerce Não tem idade",
    subTitulo:
      "As estratégias dos varejistas para o publico com mais de 50 anos",
    linkRevista:
      "//e.issuu.com/embed.html?d=revista_ecb_63-06&hideIssuuLogo=true&showOtherPublicationsAsSuggestions=true&u=ecommercebrasil&hideShareButton=true&pageNumber=1",
    hover: false,
    backgroundCapa: require("./assets/imgs/imgs_63/backgroundCapa.jpg"),
    imgCapaRevista: require("./assets/imgs/imgs_63/capa_63.png"),
    backgroundCapaRevista: require("./assets/imgs/imgs_63/Cartoes.jpg"),
    imgBackgroundLeft: require("./assets/imgs/imgs_63/Caixas.jpg"),
    imgDestaqueLeft: require("./assets/imgs/imgs_63/Senhora.jpg"),
    imgRevistaExibe: require("./assets/capas/63.png"),
  },
  {
    id: 62,
    edicao: "Edição 62",
    titulo: "A moda agora é sustentabilidade",
    subTitulo:
      "Como o e-commerce influencia nas tendências de roupas e acessórios",
    linkRevista:
      "//e.issuu.com/embed.html?d=revista_ecb_62_sem_corte&hideIssuuLogo=true&showOtherPublicationsAsSuggestions=true&u=ecommercebrasil&hideShareButton=true&pageNumber=1",
    hover: false,
    backgroundCapa: require("./assets/imgs/imgs_62/backgroundCapa.jpg"),
    imgCapaRevista: require("./assets/imgs/imgs_62/capa_62.jpg"),
    backgroundCapaRevista: require("./assets/imgs/imgs_62/backgroundCapaRevista.jpg"),
    imgBackgroundLeft: require("./assets/imgs/imgs_62/imgBackgroundLeft.jpg"),
    imgDestaqueLeft: require("./assets/imgs/imgs_62/imgDestaqueLeft.jpg"),
    imgRevistaExibe: require("./assets/capas/62.jpg"),
    colorText: "#fff",
  },
  {
    id: 61,
    edicao: "Edição 61",
    titulo: "Imposto Digital",
    subTitulo: "O que a reforma tributária pode mudar no e-commerce brasileiro",
    linkRevista:
      "//e.issuu.com/embed.html?d=revista_ecb_61&hideIssuuLogo=true&showOtherPublicationsAsSuggestions=true&u=ecommercebrasil&hideShareButton=true&pageNumber=1",
    hover: false,
    backgroundCapa: "",
    imgCapaRevista: require("./assets/imgs/imgs_61/capa_61.png"),
    backgroundCapaRevista: "",
    imgBackgroundLeft: "",
    imgDestaqueLeft: "",
    imgRevistaExibe: require("./assets/capas/61.jpg"),
  },
  {
    id: 60,
    edicao: "Edição 60",
    titulo: "O que levaremos para 2021?",
    subTitulo:
      "No ano em que toda semana parecia uma Black Friday, o e-commerce se consolidou",
    linkRevista:
      "//e.issuu.com/embed.html?d=revista_ecb_60-06&hideIssuuLogo=true&showOtherPublicationsAsSuggestions=true&u=ecommercebrasil&hideShareButton=true&pageNumber=1",
    hover: false,
    backgroundCapa: "",
    imgCapaRevista: require("./assets/imgs/imgs_60/capa_60.jpg"),
    backgroundCapaRevista: "",
    imgBackgroundLeft: "",
    imgDestaqueLeft: "",
    imgRevistaExibe: require("./assets/capas/60.jpg"),
  },
  {
    id: 59,
    edicao: "Edição 59",
    titulo: "E-grocery não é só “modinha“",
    subTitulo:
      "Setor de supermercados e bebidas é realidade que veio para ficar no e-commerce",
    linkRevista:
      "//e.issuu.com/embed.html?d=revista_ecb_59__1_&hideIssuuLogo=true&showOtherPublicationsAsSuggestions=true&u=ecommercebrasil&hideShareButton=true&pageNumber=1",
    hover: false,
    backgroundCapa: "",
    imgCapaRevista: require("./assets/imgs/imgs_59/capa_59.jpg"),
    backgroundCapaRevista: "",
    imgBackgroundLeft: "",
    imgDestaqueLeft: "",
    imgRevistaExibe: require("./assets/capas/59.jpg"),
  },
  {
    id: 58,
    edicao: "Edição 58",
    titulo: "Mobiliando a casa graças ao e-commerce",
    subTitulo: "Vendas online de móveis e decoração disparam com a pandemia",
    linkRevista:
      "//e.issuu.com/embed.html?d=revista_ecb_58-08__2___1_&hideIssuuLogo=true&showOtherPublicationsAsSuggestions=true&u=ecommercebrasil&hideShareButton=true&pageNumber=1",
    hover: false,
    backgroundCapa: "",
    imgCapaRevista: require("./assets/imgs/imgs_58/capa_58.jpeg"),
    backgroundCapaRevista: "",
    imgBackgroundLeft: "",
    imgDestaqueLeft: "",
    imgRevistaExibe: require("./assets/capas/58.jpg"),
  },
  {
    id: 57,
    edicao: "Edição 57",
    titulo: "Reinvenção contra o caos",
    subTitulo: "E-commerce aquecido e novo comportamento dos consumidores",
    linkRevista:
      "//e.issuu.com/embed.html?d=revista_ecb_57-05-final__1_&hideIssuuLogo=true&showOtherPublicationsAsSuggestions=true&u=ecommercebrasil&hideShareButton=true&pageNumber=1",
    hover: false,
    backgroundCapa: "",
    imgCapaRevista: require("./assets/imgs/imgs_57/capa_57.jpeg"),
    backgroundCapaRevista: "",
    imgBackgroundLeft: "",
    imgDestaqueLeft: "",
    imgRevistaExibe: require("./assets/capas/57.jpg"),
  },
  {
    id: 56,
    edicao: "Edição 56",
    titulo: "E-commerce em tempos de pandemia.",
    subTitulo: "Os impactos e as saídas para o varejo em meio à quarentena",
    linkRevista:
      "//e.issuu.com/embed.html?d=revista_ecb_56-05__1_&hideIssuuLogo=true&showOtherPublicationsAsSuggestions=true&u=ecommercebrasil&hideShareButton=true&pageNumber=1",
    hover: false,
    imgCapaRevista: require("./assets/imgs/imgs_56/capa_56.png"),
    backgroundCapaRevista: "",
    imgBackgroundLeft: "",
    imgDestaqueLeft: "",
    imgRevistaExibe: require("./assets/capas/56.jpg"),
  },
  {
    id: 55,
    edicao: "Edição 55",
    titulo: "O funcionário em primeiro lugar.",
    subTitulo: "O que pensar para construir um ambiente positivo na empresa",
    linkRevista:
      "//e.issuu.com/embed.html?d=revista-ecb-55_final&hideIssuuLogo=true&showOtherPublicationsAsSuggestions=true&u=ecommercebrasil&hideShareButton=true&pageNumber=1",
    hover: false,
    backgroundCapa: "",
    imgCapaRevista: require("./assets/imgs/imgs_55/capa_55.png"),
    backgroundCapaRevista: "",
    imgBackgroundLeft: "",
    imgDestaqueLeft: "",
    imgRevistaExibe: require("./assets/capas/55.jpg"),
  },
  {
    id: 54,
    edicao: "Edição 54",
    titulo: "A VOLTA POR CIMA DO E-COMMERCE",
    subTitulo:
      "2020 promete um novo capítulo para o coméricio eletrônico brasileiro",
    linkRevista:
      "//e.issuu.com/embed.html?d=revista-ecb-54_final-site&hideIssuuLogo=true&showOtherPublicationsAsSuggestions=true&u=ecommercebrasil&hideShareButton=true&pageNumber=1",
    hover: false,
    backgroundCapa: "",
    imgCapaRevista: require("./assets/imgs/imgs_54/capa_54.jpg"),
    backgroundCapaRevista: "",
    imgBackgroundLeft: "",
    imgDestaqueLeft: "",
    imgRevistaExibe: require("./assets/capas/54.jpg"),
  },
  {
    id: 53,
    edicao: "Edição 53",
    titulo: "Realidade alternativa",
    subTitulo: "Varejo vislumbra nova era da interatividade",
    linkRevista:
      "//e.issuu.com/embed.html?d=revista-e53_final-sem-marca-de-corte&hideIssuuLogo=true&showOtherPublicationsAsSuggestions=true&u=ecommercebrasil&hideShareButton=true&pageNumber=1",
    hover: false,
    backgroundCapa: "",
    imgCapaRevista: require("./assets/imgs/imgs_53/capa_53.jpg"),
    backgroundCapaRevista: "",
    imgBackgroundLeft: "",
    imgDestaqueLeft: "",
    imgRevistaExibe: require("./assets/capas/53.jpg"),
  },
  {
    id: 52,
    edicao: "Edição 52",
    titulo: "Mãos à obra",
    subTitulo:
      "Saiba o que a sua loja precisa para se adequar á lei geral de proteção de dados",
    linkRevista:
      "//e.issuu.com/embed.html?d=revista-ecb_52-leve&hideIssuuLogo=true&showOtherPublicationsAsSuggestions=true&u=ecommercebrasil&hideShareButton=true&pageNumber=1",
    hover: false,
    backgroundCapa: "",
    imgCapaRevista: require("./assets/imgs/imgs_52/capa_52.jpg"),
    backgroundCapaRevista: "",
    imgBackgroundLeft: "",
    imgDestaqueLeft: "",
    imgRevistaExibe: require("./assets/imgs/imgs_52/thumb_52.png"),
  },
  {
    id: 51,
    edicao: "Edição 51",
    titulo: "Marketplace ou banco?",
    subTitulo: "Como o e-commerce vai além das lojas convencionais",
    linkRevista:
      "//e.issuu.com/embed.html?d=revista-ecb-51-10-leve&hideIssuuLogo=true&showOtherPublicationsAsSuggestions=true&u=ecommercebrasil&hideShareButton=true&pageNumber=1",
    hover: false,
    backgroundCapa: "",
    imgCapaRevista: require("./assets/imgs/imgs_51/capa_51.jpg"),
    backgroundCapaRevista: "",
    imgBackgroundLeft: "",
    imgDestaqueLeft: "",
    imgRevistaExibe: require("./assets/capas/51.jpg"),
  },

  {
    id: 50,
    edicao: "Edição 50",
    titulo: "O mundo dos leilões no WhatsApp",
    subTitulo: "Como o e-commerce vai além das lojas convencionais",
    linkRevista:
      "https://e.issuu.com/embed.html?identifier=p4b8weyyntwt&embedType=script#2527949/69191574",
    hover: false,
    backgroundCapa: "",
    imgCapaRevista: require("./assets/imgs/imgs_50/capa_50.png"),
    backgroundCapaRevista: "",
    imgBackgroundLeft: "",
    imgDestaqueLeft: "",
    imgRevistaExibe: require("./assets/capas/19.jpg"),
  },
  {
    id: 49,
    edicao: "Edição 49",
    titulo: "Perspectivas para o e-commerce",
    subTitulo: "O que o varejo espera de 2019",
    linkRevista:
      "https://e.issuu.com/embed.html?identifier=7qeyfcfo466g&embedType=script#2527949/68517874",
    hover: false,
    backgroundCapa: "",
    imgCapaRevista: require("./assets/imgs/imgs_49/capa_49.jpg"),
    backgroundCapaRevista: "",
    imgBackgroundLeft: "",
    imgDestaqueLeft: "",
    imgRevistaExibe: require("./assets/imgs/imgs_49/thumb_49.png"),
  },
  {
    id: 48,
    edicao: "Edição 48",
    titulo: "Pós-Black Friday",
    subTitulo:
      "Saiba como organizar a sua loja para surpreender e cativar o cliente depois da maior data do varejo",
    linkRevista:
      "https://e.issuu.com/embed.html?identifier=3nu0mr113sjf&embedType=script#2527949/66783817",
    hover: false,
    backgroundCapa: "",
    imgCapaRevista: require("./assets/imgs/imgs_48/capa_48.png"),
    backgroundCapaRevista: "",
    imgBackgroundLeft: "",
    imgDestaqueLeft: "",
    imgRevistaExibe: require("./assets/imgs/imgs_48/thumb_48.png"),
  },
  {
    id: 47,
    edicao: "Edição 47",
    titulo: "Como ficará o e-commerce",
    subTitulo: "Com as novas regras de proteção de dados?",
    linkRevista:
      "https://e.issuu.com/embed.html?identifier=geeiuf950wnx&embedType=script#2527949/78355418",
    hover: false,
    backgroundCapa: "",
    imgCapaRevista: require("./assets/imgs/imgs_47/capa_47.png"),
    backgroundCapaRevista: "",
    imgBackgroundLeft: "",
    imgDestaqueLeft: "",
    imgRevistaExibe: require("./assets/imgs/imgs_47/thumb_47.png"),
  },
  {
    id: 46,
    edicao: "Edição 46",
    titulo: "O Brasil aos olhos do mundo",
    subTitulo:
      "O que eles pensam do e-commerce nacional e quais estratégias para lidar conosco",
    linkRevista:
      "https://e.issuu.com/embed.html?identifier=03h7v2lk4z66&embedType=script#2527949/64199980",
    hover: false,
    backgroundCapa: "",
    imgCapaRevista: require("./assets/imgs/imgs_46/capa_46.jpg"),
    backgroundCapaRevista: "",
    imgBackgroundLeft: "",
    imgDestaqueLeft: "",
    imgRevistaExibe: require("./assets/imgs/imgs_46/thumb_46.png"),
  },
  {
    id: 45,
    edicao: "Edição 45",
    titulo: "Fintechs",
    subTitulo: "Revolução do consumo e dos servições no Brasil",
    linkRevista:
      "https://e.issuu.com/embed.html?identifier=6yj0dyq57oia&embedType=script#2527949/62775681",
    hover: false,
    backgroundCapa: "",
    imgCapaRevista: require("./assets/imgs/imgs_45/capa_45.png"),
    backgroundCapaRevista: "",
    imgBackgroundLeft: "",
    imgDestaqueLeft: "",
    imgRevistaExibe: require("./assets/imgs/imgs_45/thumb_45.png"),
  },
  {
    id: 44,
    edicao: "Edição 44",
    titulo: "Experiencia de compra offline",
    subTitulo:
      "Entenda como o e-commerce está levando sua experiência de compra para o offline",
    linkRevista:
      "https://e.issuu.com/embed.html?identifier=6bn287o552yq&embedType=script#2527949/60195176",
    hover: false,
    backgroundCapa: "",
    imgCapaRevista: require("./assets/imgs/imgs_44/capa_44.jpg"),
    backgroundCapaRevista: "",
    imgBackgroundLeft: "",
    imgDestaqueLeft: "",
    imgRevistaExibe: require("./assets/imgs/imgs_44/thumb_44.png"),
  },
  {
    id: 43,
    edicao: "Edição 43",
    titulo: "Marketplaces",
    subTitulo: "A hora e a vez dos marketplaces no Brasil",
    linkRevista:
      "https://e.issuu.com/embed.html?identifier=rnovdj122kqo&embedType=script#2527949/58869114",
    hover: false,
    backgroundCapa: "",
    imgCapaRevista: require("./assets/imgs/imgs_43/capa_43.jpg"),
    backgroundCapaRevista: "",
    imgBackgroundLeft: "",
    imgDestaqueLeft: "",
    imgRevistaExibe: require("./assets/imgs/imgs_43/thumb_43.png"),
  },
  {
    id: 42,
    edicao: "Edição 42",
    titulo: "Crescimento",
    subTitulo: "Um panorama e perspectivas de crescimento para o e-commerce",
    linkRevista:
      "https://e.issuu.com/embed.html?identifier=ihaitif2vpod&embedType=script#2527949/57532132",
    hover: false,
    imgCapaRevista: require("./assets/imgs/imgs_42/capa_42.png"),
    backgroundCapaRevista: "",
    imgBackgroundLeft: "",
    imgDestaqueLeft: "",
    imgRevistaExibe: require("./assets/imgs/imgs_42/thumb_42.png"),
  },
  {
    id: 41,
    edicao: "Edição 41",
    titulo: "Indústria",
    subTitulo: "O e-commerce conquistou a indústria!",
    linkRevista:
      "https://e.issuu.com/embed.html?identifier=2l25opyntj67&embedType=script#2527949/54579839",
    hover: false,
    backgroundCapa: "",
    imgCapaRevista: require("./assets/imgs/imgs_41/capa_41.png"),
    backgroundCapaRevista: "",
    imgBackgroundLeft: "",
    imgDestaqueLeft: "",
    imgRevistaExibe: require("./assets/imgs/imgs_41/thumb_41.png"),
  },
  {
    id: 40,
    edicao: "Edição 40",
    titulo: "Atenção, varejista",
    subTitulo: "A black friday já começou!",
    linkRevista:
      "https://e.issuu.com/embed.html?identifier=qjjj9jwpz9tn&embedType=script#2527949/52263260",
    hover: false,
    backgroundCapa: "",
    imgCapaRevista: require("./assets/imgs/imgs_40/capa_40.png"),
    backgroundCapaRevista: "",
    imgBackgroundLeft: "",
    imgDestaqueLeft: "",
    imgRevistaExibe: require("./assets/imgs/imgs_40/thumb_40.png"),
  },
  {
    id: 39,
    edicao: "Edição 39",
    titulo: "Diversão sem fronteiras",
    subTitulo:
      "Como o mercado de entretenimento online abriu as asas no Brasil",
    linkRevista:
      "https://e.issuu.com/embed.html?identifier=w8v98fues60c&embedType=script#2527949/50348641",
    hover: false,
    backgroundCapa: "",
    imgCapaRevista: require("./assets/imgs/imgs_39/capa_39.png"),
    backgroundCapaRevista: "",
    imgBackgroundLeft: "",
    imgDestaqueLeft: "",
    imgRevistaExibe: require("./assets/imgs/imgs_39/thumb_39.png"),
  },
  {
    id: 38,
    edicao: "Edição 38",
    titulo: "Operação",
    subTitulo:
      "Um segundo olhar sobre a sua operação e o mercado de e-commerce no Brasil",
    linkRevista:
      "https://e.issuu.com/embed.html?identifier=w1xict459osm&embedType=script#2527949/47779020",
    hover: false,
    backgroundCapa: "",
    imgCapaRevista: require("./assets/imgs/imgs_38/capa_38.png"),
    backgroundCapaRevista: "",
    imgBackgroundLeft: "",
    imgDestaqueLeft: "",
    imgRevistaExibe: require("./assets/imgs/imgs_38/thumb_38.png"),
  },

  {
    id: 37,
    edicao: "Edição 37",
    titulo: "Quanto menos é mais",
    subTitulo: "Não perca a guerra para o adblock",
    linkRevista:
      "https://e.issuu.com/embed.html?identifier=c6ruor6zse8h&embedType=script#2527949/46899368",
    hover: false,
    backgroundCapa: "",
    imgCapaRevista: require("./assets/imgs/imgs_37/capa_37.jpg"),
    backgroundCapaRevista: "",
    imgBackgroundLeft: "",
    imgDestaqueLeft: "",
    imgRevistaExibe: require("./assets/imgs/imgs_37/thumb_37.png"),
  },
  {
    id: 36,
    edicao: "Edição 36",
    titulo: "Código do consumidor",
    subTitulo:
      "O codigo do consumidor está prestes a passar por mudanças, veja como isso pode afetar o seu e-commerce",
    linkRevista:
      "https://e.issuu.com/embed.html?identifier=uj55j0gsol9r&embedType=script#2527949/43470992",
    hover: false,
    backgroundCapa: "",
    imgCapaRevista: require("./assets/imgs/imgs_36/capa_36.jpg"),
    backgroundCapaRevista: "",
    imgBackgroundLeft: "",
    imgDestaqueLeft: "",
    imgRevistaExibe: require("./assets/imgs/imgs_36/thumb_36.png"),
  },
  {
    id: 35,
    edicao: "Edição 35",
    titulo: "Gerir pessoas e processos",
    subTitulo:
      "Quando gerir pessoas é mais importante (e mais difícil) do que gerir processos",
    linkRevista:
      "https://e.issuu.com/embed.html?identifier=4r9nnfow12wc&embedType=script#2527949/39771124",
    hover: false,
    backgroundCapa: "",
    imgCapaRevista: require("./assets/imgs/imgs_35/capa_35.jpg"),
    backgroundCapaRevista: "",
    imgBackgroundLeft: "",
    imgDestaqueLeft: "",
    imgRevistaExibe: require("./assets/imgs/imgs_35/thumb_35.png"),
  },
  {
    id: 34,
    edicao: "Edição 34",
    titulo: "Gestão financeira",
    subTitulo: "O desafio pós convênio 93/2015",
    linkRevista:
      "https://e.issuu.com/embed.html?identifier=30ov36r8gps7&embedType=script#2527949/38012650",
    hover: false,
    backgroundCapa: "",
    imgCapaRevista: require("./assets/imgs/imgs_34/capa_34.jpg"),
    backgroundCapaRevista: "",
    imgBackgroundLeft: "",
    imgDestaqueLeft: "",
    imgRevistaExibe: require("./assets/imgs/imgs_34/thumb_34.png"),
  },
  {
    id: 33,
    edicao: "Edição 33",
    titulo: "A internacionalização do e-commerce",
    subTitulo: "O Brasil está preparado?",
    linkRevista:
      "https://e.issuu.com/embed.html?identifier=hin0ngcblq71&embedType=script#2527949/36770956",
    hover: false,
    backgroundCapa: "",
    imgCapaRevista: require("./assets/imgs/imgs_33/capa_33.jpg"),
    backgroundCapaRevista: "",
    imgBackgroundLeft: "",
    imgDestaqueLeft: "",
    imgRevistaExibe: require("./assets/imgs/imgs_33/thumb_33.png"),
  },
  {
    id: 32,
    edicao: "Edição 32",
    titulo: "Plus size",
    subTitulo: "A revolução plus size começa na internet",
    linkRevista:
      "https://e.issuu.com/embed.html?identifier=2enzwhhowby5&embedType=script#2527949/35214219",
    hover: false,
    backgroundCapa: "",
    imgCapaRevista: require("./assets/imgs/imgs_32/capa_32.jpg"),
    backgroundCapaRevista: "",
    imgBackgroundLeft: "",
    imgDestaqueLeft: "",
    imgRevistaExibe: require("./assets/imgs/imgs_32/thumb_32.png"),
  },
  {
    id: 31,
    edicao: "Edição 31",
    titulo: "Apresentação do produto",
    subTitulo: "A difícil arte de saber apresentar o seu produto. Você domina?",
    linkRevista:
      "https://e.issuu.com/embed.html?identifier=4itsvs3o4zjy&embedType=script#2527949/34064682",
    hover: false,
    backgroundCapa: "",
    imgCapaRevista: require("./assets/imgs/imgs_31/capa_31.jpg"),
    backgroundCapaRevista: "",
    imgBackgroundLeft: "",
    imgDestaqueLeft: "",
    imgRevistaExibe: require("./assets/imgs/imgs_31/thumb_31.png"),
  },
  {
    id: 30,
    edicao: "Edição 30",
    titulo: "Logística",
    subTitulo: "O desafio de se fazer logística no Brasil",
    linkRevista:
      "https://e.issuu.com/embed.html?identifier=6jqe8p8j73m4&embedType=script#2527949/32529002",
    hover: false,
    backgroundCapa: "",
    imgCapaRevista: require("./assets/imgs/imgs_30/capa_30.jpg"),
    backgroundCapaRevista: "",
    imgBackgroundLeft: "",
    imgDestaqueLeft: "",
    imgRevistaExibe: require("./assets/imgs/imgs_30/thumb_30.png"),
  },
  {
    id: 29,
    edicao: "Edição 29",
    titulo: "Forum E-Commerce Brasil",
    subTitulo: "Celebra os 20 anos do comércio eletrônico brasileiro",
    linkRevista:
      "https://e.issuu.com/embed.html?identifier=nxgypfjlsffz&embedType=script#2527949/30556525",
    hover: false,
    imgCapaRevista: require("./assets/imgs/imgs_29/capa_29.jpg"),
    backgroundCapaRevista: "",
    imgBackgroundLeft: "",
    imgDestaqueLeft: "",
    imgRevistaExibe: require("./assets/imgs/imgs_29/thumb_29.png"),
  },
  {
    id: 28,
    edicao: "Edição 28",
    titulo: "Dê Boas-vindas!",
    subTitulo: "A indústria chegou ao varejo",
    linkRevista:
      "https://e.issuu.com/embed.html?identifier=nbfy3iufr20m&embedType=script#2527949/14760520",
    hover: false,
    backgroundCapa: "",
    imgCapaRevista: require("./assets/imgs/imgs_28/capa_28.jpg"),
    backgroundCapaRevista: "",
    imgBackgroundLeft: "",
    imgDestaqueLeft: "",
    imgRevistaExibe: require("./assets/imgs/imgs_28/thumb_28.png"),
  },
  {
    id: 27,
    edicao: "Edição 27",
    titulo: "Ominichannel",
    subTitulo:
      "Ominichannel é a tendência do momento no E-commerce, mas será que você também pode fazê-lo?",
    linkRevista:
      "https://e.issuu.com/embed.html?identifier=2lf1szj65f2e&embedType=script#2527949/13630532",
    hover: false,
    backgroundCapa: "",
    imgCapaRevista: require("./assets/imgs/imgs_27/capa_27.jpg"),
    backgroundCapaRevista: "",
    imgBackgroundLeft: "",
    imgDestaqueLeft: "",
    imgRevistaExibe: require("./assets/imgs/imgs_27/thumb_27.png"),
  },
  {
    id: 26,
    edicao: "Edição 26",
    titulo: "20 anos do E-commerce no Brasil",
    subTitulo: "Veja uma análise completa do passado presente e futuro",
    linkRevista:
      "https://e.issuu.com/embed.html?identifier=yryxo8vo4gwk&embedType=script#2527949/12365188",
    hover: false,
    backgroundCapa: "",
    imgCapaRevista: require("./assets/imgs/imgs_26/capa_26.jpg"),
    backgroundCapaRevista: "",
    imgBackgroundLeft: "",
    imgDestaqueLeft: "",
    imgRevistaExibe: require("./assets/imgs/imgs_26/thumb_26.png"),
  },
  {
    id: 25,
    edicao: "Edição 25",
    titulo: "2015 já chegou!",
    subTitulo: "Saiba oque esperar deste ano para o seu e-commerce",
    linkRevista:
      "https://e.issuu.com/embed.html?identifier=5f43yor6grw1&embedType=script#2527949/11736029",
    hover: false,
    backgroundCapa: "",
    imgCapaRevista: require("./assets/imgs/imgs_25/capa_25.jpg"),
    backgroundCapaRevista: "",
    imgBackgroundLeft: "",
    imgDestaqueLeft: "",
    imgRevistaExibe: require("./assets/imgs/imgs_25/thumb_25.png"),
  },
  {
    id: 24,
    edicao: "Edição 24",
    titulo: "Como você está se preparando?",
    subTitulo: "Mobile é a grande aposta de 2015 para o E-commerce.",
    linkRevista:
      "https://e.issuu.com/embed.html?identifier=rowmkl87paib&embedType=script#2527949/10614158",
    hover: false,
    backgroundCapa: "",
    imgCapaRevista: require("./assets/imgs/imgs_24/capa_24.jpg"),
    backgroundCapaRevista: "",
    imgBackgroundLeft: "",
    imgDestaqueLeft: "",
    imgRevistaExibe: require("./assets/imgs/imgs_24/thumb_24.png"),
  },

  {
    id: 23,
    edicao: "Edição 23",
    titulo: "Fórum E-Commerce Brasil 2014",
    subTitulo: "Confira a cobertura",
    linkRevista:
      "https://e.issuu.com/embed.html?identifier=4nidtztl5ieu&embedType=script#2527949/9793995",
    hover: false,
    backgroundCapa: "",
    imgCapaRevista: require("./assets/imgs/imgs_23/capa_23.jpg"),
    backgroundCapaRevista: "",
    imgBackgroundLeft: "",
    imgDestaqueLeft: "",
    imgRevistaExibe: require("./assets/imgs/imgs_23/thumb_23.png"),
  },
  {
    id: 22,
    edicao: "Edição 22",
    titulo: "Fraude",
    subTitulo:
      "A fraude é uma realidade do E-commerce Brasileiro. Como Combatê-la?",
    linkRevista:
      "https://e.issuu.com/embed.html?identifier=r81cqqk7mcix&embedType=script#2527949/9056207",
    hover: false,
    backgroundCapa: "",
    imgCapaRevista: require("./assets/imgs/imgs_22/capa_22.jpg"),
    backgroundCapaRevista: "",
    imgBackgroundLeft: "",
    imgDestaqueLeft: "",
    imgRevistaExibe: require("./assets/imgs/imgs_22/thumb_22.png"),
  },
  {
    id: 21,
    edicao: "Edição 21",
    titulo: "Arquitetura da informação",
    subTitulo:
      "A arquitetura da informação pode te ajudar no seu maior objetivo: A conversão",
    linkRevista:
      "https://e.issuu.com/embed.html?identifier=j8y5063c360j&embedType=script#2527949/8359027",
    hover: false,
    backgroundCapa: "",
    imgCapaRevista: require("./assets/imgs/imgs_21/capa_21.jpg"),
    backgroundCapaRevista: "",
    imgBackgroundLeft: "",
    imgDestaqueLeft: "",
    imgRevistaExibe: require("./assets/imgs/imgs_21/thumb_21.png"),
  },
  {
    id: 20,
    edicao: "Edição 20",
    titulo: "Business Inteligence e Big Data",
    subTitulo:
      "Você tem utilizado business inteligence e big data ao seu favor?",
    linkRevista:
      "https://e.issuu.com/embed.html?identifier=cytds3lca8rr&embedType=script#2527949/7611369",
    hover: false,
    backgroundCapa: "",
    imgCapaRevista: require("./assets/imgs/imgs_20/capa_20.jpg"),
    backgroundCapaRevista: "",
    imgBackgroundLeft: "",
    imgDestaqueLeft: "",
    imgRevistaExibe: require("./assets/imgs/imgs_20/thumb_20.png"),
  },
  {
    id: 19,
    edicao: "Edição 19",
    titulo: "Copa 2014",
    subTitulo: "Como o E-commerce deve se preparar para a copa 2014",
    linkRevista:
      "https://e.issuu.com/embed.html?identifier=ttb7jqgzdcgd&embedType=script#2527949/6823016",
    hover: false,
    backgroundCapa: "",
    imgCapaRevista: require("./assets/imgs/imgs_19/capa_19.jpg"),
    backgroundCapaRevista: "",
    imgBackgroundLeft: "",
    imgDestaqueLeft: "",
    imgRevistaExibe: require("./assets/imgs/imgs_19/thumb_19.png"),
  },
  {
    id: 18,
    edicao: "Edição 18",
    titulo: "Crescimento",
    subTitulo: "Qual é a escada que leva ao crescimento?",
    linkRevista:
      "https://e.issuu.com/embed.html?identifier=e5u289855tjv&embedType=script#2527949/5986766",
    hover: false,
    backgroundCapa: "",
    imgCapaRevista: require("./assets/imgs/imgs_18/capa_18.jpg"),
    backgroundCapaRevista: "",
    imgBackgroundLeft: "",
    imgDestaqueLeft: "",
    imgRevistaExibe: require("./assets/imgs/imgs_18/thumb_18.png"),
  },
  {
    id: 17,
    edicao: "Edição 17",
    titulo: "Você tem um problema, eles têm a solução",
    subTitulo: "Startups que resolvem tudo para seu E-commerce",
    linkRevista:
      "https://e.issuu.com/embed.html?identifier=actf489t0pd9&embedType=script#2527949/5344807",
    hover: false,
    backgroundCapa: "",
    imgCapaRevista: require("./assets/imgs/imgs_17/capa_17.jpg"),
    backgroundCapaRevista: "",
    imgBackgroundLeft: "",
    imgDestaqueLeft: "",
    imgRevistaExibe: require("./assets/imgs/imgs_17/thumb_17.png"),
  },
  {
    id: 16,
    edicao: "Edição 16",
    titulo: "História do comércio",
    subTitulo: "Oque o E-commerce pode aprender com a história do comércio",
    linkRevista:
      "https://e.issuu.com/embed.html?identifier=fr8lx3qhd2r0&embedType=script#2527949/4365062",
    hover: false,
    backgroundCapa: "",
    imgCapaRevista: require("./assets/imgs/imgs_16/capa_16.jpg"),
    backgroundCapaRevista: "",
    imgBackgroundLeft: "",
    imgDestaqueLeft: "",
    imgRevistaExibe: require("./assets/imgs/imgs_16/thumb_16.png"),
  },
  {
    id: 15,
    edicao: "Edição 15",
    titulo: "Compra & Venda",
    subTitulo: "Comprar bem para vender melhor",
    linkRevista:
      "https://e.issuu.com/embed.html?identifier=hmr2jvl6y7dy&embedType=script#2527949/3742899",
    hover: false,
    imgCapaRevista: require("./assets/imgs/imgs_15/capa_15.jpg"),
    backgroundCapaRevista: "",
    imgBackgroundLeft: "",
    imgDestaqueLeft: "",
    imgRevistaExibe: require("./assets/imgs/imgs_15/thumb_15.png"),
  },
  {
    id: 14,
    edicao: "Edição 14",
    titulo: "Atenção & Intenção",
    subTitulo: "Da economia da atenção para a economia da intenção",
    linkRevista:
      "https://e.issuu.com/embed.html?identifier=uqkyttd8m2io&embedType=script#2527949/2120168",
    hover: false,
    backgroundCapa: "",
    imgCapaRevista: require("./assets/imgs/imgs_14/capa_14.jpg"),
    backgroundCapaRevista: "",
    imgBackgroundLeft: "",
    imgDestaqueLeft: "",
    imgRevistaExibe: require("./assets/imgs/imgs_14/thumb_14.png"),
  },
  {
    id: 13,
    edicao: "Edição 13",
    titulo: "2013",
    subTitulo: "Oque 2013 reserva para o E-commerce Brasileiro",
    linkRevista:
      "https://e.issuu.com/embed.html?identifier=p5udwurvpvwl&embedType=script#2527949/1671342",
    hover: false,
    backgroundCapa: "",
    imgCapaRevista: require("./assets/imgs/imgs_13/capa_13.jpg"),
    backgroundCapaRevista: "",
    imgBackgroundLeft: "",
    imgDestaqueLeft: "",
    imgRevistaExibe: require("./assets/imgs/imgs_13/thumb_13.png"),
  },
  {
    id: 12,
    edicao: "Edição 12",
    titulo: "Sortimentos",
    subTitulo: "O novo desafio do E-commerce Brasileiro",
    linkRevista:
      "https://e.issuu.com/embed.html?identifier=6bxbh432ooa8&embedType=script#2527949/1041711",
    hover: false,
    backgroundCapa: "",
    imgCapaRevista: require("./assets/imgs/imgs_12/capa_12.jpg"),
    backgroundCapaRevista: "",
    imgBackgroundLeft: "",
    imgDestaqueLeft: "",
    imgRevistaExibe: require("./assets/imgs/imgs_12/thumb_12.png"),
  },
  {
    id: 11,
    edicao: "Edição 11",
    titulo: "A audácia dos pequenos",
    subTitulo: "Indo além da mera sobrevivência",
    linkRevista:
      "https://e.issuu.com/embed.html?identifier=z8xuj9vzz3ff&embedType=script#2527949/2829347",
    hover: false,
    backgroundCapa: "",
    imgCapaRevista: require("./assets/imgs/imgs_11/capa_11.jpg"),
    backgroundCapaRevista: "",
    imgBackgroundLeft: "",
    imgDestaqueLeft: "",
    imgRevistaExibe: require("./assets/imgs/imgs_11/thumb_11.png"),
  },

  {
    id: 10,
    edicao: "Edição 10",
    titulo: "Atendimento",
    subTitulo: "Pilar indispensável",
    linkRevista:
      "https://e.issuu.com/embed.html?identifier=oc9i2p3th1py&embedType=script#2527949/4455203",
    hover: false,
    backgroundCapa: "",
    imgCapaRevista: require("./assets/imgs/imgs_10/capa_10.jpg"),
    backgroundCapaRevista: "",
    imgBackgroundLeft: "",
    imgDestaqueLeft: "",
    imgRevistaExibe: require("./assets/imgs/imgs_10/thumb_10.png"),
  },
  {
    id: 9,
    edicao: "Edição 9",
    titulo: "Modelos de negócios",
    subTitulo: "Para o varejo eletrônico",
    linkRevista:
      "https://e.issuu.com/embed.html?identifier=63rw8trwk2ph&embedType=script#2527949/3887771",
    hover: false,
    backgroundCapa: "",
    imgCapaRevista: require("./assets/imgs/imgs_9/capa_9.jpg"),
    backgroundCapaRevista: "",
    imgBackgroundLeft: "",
    imgDestaqueLeft: "",
    imgRevistaExibe: require("./assets/imgs/imgs_9/thumb_09.png"),
  },
  {
    id: 8,
    edicao: "Edição 8",
    titulo: "Checkout",
    subTitulo: "Os novos rumos do checkout",
    linkRevista:
      "https://e.issuu.com/embed.html?identifier=vx2hufw45879&embedType=script#2527949/3571409",
    hover: false,
    backgroundCapa: "",
    imgCapaRevista: require("./assets/imgs/imgs_8/capa_8.jpg"),
    backgroundCapaRevista: "",
    imgBackgroundLeft: "",
    imgDestaqueLeft: "",
    imgRevistaExibe: require("./assets/imgs/imgs_8/thumb_08.png"),
  },
  {
    id: 7,
    edicao: "Edição 7",
    titulo: "Conteúdo",
    subTitulo: "O grande aliado",
    linkRevista:
      "https://e.issuu.com/embed.html?identifier=gkulcxxhjnp6&embedType=script#2527949/7337400",
    hover: false,
    backgroundCapa: "",
    imgCapaRevista: require("./assets/imgs/imgs_7/capa_7.jpg"),
    backgroundCapaRevista: "",
    imgBackgroundLeft: "",
    imgDestaqueLeft: "",
    imgRevistaExibe: require("./assets/imgs/imgs_7/thumb_07.png"),
  },
  {
    id: 6,
    edicao: "Edição 6",
    titulo: "26 dicas",
    subTitulo: "Para aumentar a taxa de conversão de sua loja virtual",
    linkRevista:
      "https://e.issuu.com/embed.html?identifier=7ij3ozbx4i6n&embedType=script#2527949/10494960",
    hover: false,
    backgroundCapa: "",
    imgCapaRevista: require("./assets/imgs/imgs_6/capa_6.jpg"),
    backgroundCapaRevista: "",
    imgBackgroundLeft: "",
    imgDestaqueLeft: "",
    imgRevistaExibe: require("./assets/imgs/imgs_6/thumb_06.png"),
  },
  {
    id: 5,
    edicao: "Edição 5",
    titulo: "Link",
    subTitulo: "Building",
    linkRevista:
      "https://e.issuu.com/embed.html?identifier=lwfd54r24okf&embedType=script#2527949/2683391",
    hover: false,
    backgroundCapa: "",
    imgCapaRevista: require("./assets/imgs/imgs_5/capa_5.jpg"),
    backgroundCapaRevista: "",
    imgBackgroundLeft: "",
    imgDestaqueLeft: "",
    imgRevistaExibe: require("./assets/imgs/imgs_5/thumb_05.png"),
  },
  {
    id: 4,
    edicao: "Edição 4",
    titulo: "Natal",
    subTitulo: "Planejamento para o Natal",
    linkRevista:
      "https://e.issuu.com/embed.html?identifier=omyqbex0pmu7&embedType=script#2527949/10494976",
    hover: false,
    imgCapaRevista: require("./assets/imgs/imgs_4/capa_4.jpg"),
    backgroundCapaRevista: "",
    imgBackgroundLeft: "",
    imgDestaqueLeft: "",
    imgRevistaExibe: require("./assets/imgs/imgs_4/thumb_04.png"),
  },
  {
    id: 3,
    edicao: "Edição 3",
    titulo: "F-commerce",
    subTitulo: "A evolução social commerce",
    linkRevista:
      "https://e.issuu.com/embed.html?identifier=pu9mo0u6hj31&embedType=script#2527949/10495062",
    hover: false,
    backgroundCapa: "",
    imgCapaRevista: require("./assets/imgs/imgs_3/capa_3.jpg"),
    backgroundCapaRevista: "",
    imgBackgroundLeft: "",
    imgDestaqueLeft: "",
    imgRevistaExibe: require("./assets/imgs/imgs_3/thumb_03.png"),
  },
  {
    id: 2,
    edicao: "Edição 2",
    titulo: "A linguagem das vendas",
    subTitulo:
      "Nos apropriamos de modelos antigos ou criamos de fato algo novo?",
    linkRevista:
      "https://e.issuu.com/embed.html?identifier=o28g06pc0lsw&embedType=script#2527949/10495064",
    hover: false,
    backgroundCapa: "",
    imgCapaRevista: require("./assets/imgs/imgs_2/capa_2.jpg"),
    backgroundCapaRevista: "",
    imgBackgroundLeft: "",
    imgDestaqueLeft: "",
    imgRevistaExibe: require("./assets/imgs/imgs_2/thumb_02.png"),
  },
  {
    id: 1,
    edicao: "Edição 1",
    titulo: "Carrinho de compras abandonado",
    subTitulo: "A solução é mais simples do que você imagina",
    linkRevista:
      "https://e.issuu.com/embed.html?identifier=d1ezdllt8pwt&embedType=script#2527949/10495070",
    hover: false,
    backgroundCapa: "",
    imgCapaRevista: require("./assets/imgs/imgs_1/capa_1.jpg"),
    backgroundCapaRevista: "",
    imgBackgroundLeft: "",
    imgDestaqueLeft: "",
    imgRevistaExibe: require("./assets/imgs/imgs_1/thumb_01.png"),
  },
  {
    id: 0,
    edicao: "Edição 0",
    titulo: "",
    subTitulo: "",
    linkRevista: "",
    hover: false,
    backgroundCapa: "",
    imgCapaRevista: "",
    backgroundCapaRevista: "",
    imgBackgroundLeft: "",
    imgDestaqueLeft: "",
    imgRevistaExibe: "",
  },
];

//revistas.reverse();

export default revistas;