<template>
	<div class="header-revista">
		<header class="header-revista-conteudo">
			<div class="revista-logo">
				<router-link to="/"
					><img
						src="./../assets/novas_imgs/new_logo_revista.svg"
						loading="lazy"
				/></router-link>
			</div>
			<div class="revista-header-btns">
				<router-link to="/">Home</router-link>
				<router-link
					:to="{
						name: 'materia',
						params: { materia: 'CAPA' }
					}"
					>Revista Atual</router-link
				>
				<a href="#magazines">Revistas Anteriores</a>
			</div>
			<div class="btns-acesso">
				<router-link class="receba-link" to="/login"
					>Receba o Link de Acesso</router-link
				>
				<a class="revista-assinar-btn" href="#planos">
					<div class="revista-assinar-btn">
						<a href="#planos">Assinar</a>
					</div>
				</a>
			</div>
		</header>

		<Slide>
			<router-link to="/">Home</router-link>
			<router-link to="/login">Receba o Link de Acesso</router-link>
			<a href="#magazines">Revista Anteriores</a>
			<router-link
				:to="{
					name: 'materia',
					params: { materia: 'CAPA' }
				}"
				>Revista Atual</router-link
			>
			<a href="#planos">Assinar</a>
		</Slide>
	</div>
</template>

<script>
import { Slide } from 'vue-burger-menu'

export default {
	data() {
		return {
			email: '',
			code: '',
			name: '',
			sending: false,
			accessMethod: 0,
			isTermsAccepted: false
		}
	},
	methods: {
		sendEmail(evt) {
			const form = evt.target

			if (!form.checkValidity()) {
				return false
			}

			evt.preventDefault()

			this.sending = true

			fetch(
				this.accessMethod == 1 ? '/check-email.php' : '/check-code.php',
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded'
					},
					body:
						'email=' +
						encodeURIComponent(this.email) +
						'&code=' +
						encodeURIComponent(this.code) +
						'&name=' +
						encodeURIComponent(this.name)
				}
			)
				.then(res => res.json())
				.then(res => {
					this.sending = false

					if (!res.error) {
						this.$swal('Link de acesso enviado com sucesso!')
						return
					}

					this.$swal(
						`Erro ao enviar link de acesso${
							res.message ? ': ' + res.message : ''
						}.`
					)
				})
				.catch(() => {
					this.sending = false

					this.$swal('Erro ao enviar link de acesso.')
				})
		}
	},
	components: {
		Slide
	}
}
</script>

<style>
.header-revista {
	position: fixed;
	width: 100%;
	height: 65px;
	background-color: #fff;
	z-index: 3;
	top: 0px;
}
.header-revista .header-revista-conteudo {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 100%;
}
.header-revista .revista-header-btns {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 23%;
}
.header-revista .revista-header-btns a {
	text-transform: uppercase;
	color: #000;
	font-weight: bold;
	font-size: 16px;
	cursor: pointer;
	text-decoration: auto;
	font-family: 'Antonio';
}
.header-revista .btns-acesso {
	display: flex;
	height: 100%;
	width: 25%;
	justify-content: right;
	align-items: center;
}
.header-revista .revista-assinar-btn {
	display: flex;
	align-items: center;
	justify-content: space-around;
	height: 100%;
	background-color: #000;
	width: 35%;
}
.header-revista .btns-acesso a {
	text-transform: uppercase;
	color: #000;
	font-weight: bold;
	font-size: 16px;
	cursor: pointer;
	-webkit-text-decoration: auto;
	text-decoration: auto;
	font-family: 'Antonio';
}
.header-revista .revista-assinar-btn a {
	text-transform: uppercase;
	font-size: 16px;
	color: #fff;
	font-weight: bold;
	text-decoration: auto;
}
.bm-burger-button {
	top: 18px;
	display: none;
}
.modal-backdrop {
	background-color: #00000099;
}
.modal-content {
	padding-bottom: 10px;
}

.modal.fade .modal-dialog {
	top: 50%;
	transform: translateY(-50%);
}
.modal-dialog :is(footer, header) {
	border: none;
}
.modal-dialog .box-title {
	position: absolute;
	top: -35px;
}
.modal-dialog .custom-control {
	margin-bottom: 10px;
}
.modal-dialog .custom-control input {
	margin-right: 10px;
}
.revista-logo {
	width: 22%;
	display: flex;
	justify-content: center;
}
.receba-link {
	border-left: 1px solid;
	border-right: 1px solid;
	height: 100%;
	display: flex;
	align-items: center;
	width: 53%;
	justify-content: center;
}
@media (max-width: 815px) {
	.bm-burger-button {
		top: 18px;
		display: block;
	}
	.bm-burger-button span {
		background-color: #fff;
	}
	.header-revista .header-revista-conteudo {
		display: none;
	}
	.header-revista {
		position: fixed;
		z-index: 2;
		background-color: #000000bf;
	}
	.revista-logo a img {
		width: 82%;
	}
	/* .bm-menu .bm-item-list a{
            
        } */
	.bm-menu .bm-item-list a:hover {
		color: #0a58ca;
	}
}
</style>
