<template>
	<div id="login-revista">
		<h2 class="title-login">
			A Revista mais importante do mercado de e-commerce brasileiro
		</h2>
		<NewHeader />
		<div class="conteudo-login">
			<div class="section-planos">
				<h3 class="title-section-login">Quero Assinar</h3>
				<NewPlanos tittlePlanos="remove" tittleAssinatura="remove" />
			</div>
			<div class="section-login-form">
				<h3 class="title-section-login">Já sou assinante</h3>
				<div class="login-row row">
					<div class="login-col col-sm-12 col-md-12 col-lg-12 col-12">
						<a
							href="/login"
							aria-current="page"
							class="router-link-exact-active router-link-active"
						></a>
						<form @submit="sendEmail">
							<div role="group" class="form-group">
								<div class="form-label mb-2 box-title">
									Método de acesso
								</div>
								<div
									class="custom-control custom-radio custom-control-inline"
								>
									<input
										type="radio"
										id="customRadioInline1"
										name="customRadioInline1"
										class="custom-control-input"
										v-model="accessMethod"
										value="1"
									/>
									<label
										class="custom-control-label"
										for="customRadioInline1"
										>E-mail</label
									>
								</div>
								<div
									class="custom-control custom-radio custom-control-inline"
								>
									<input
										type="radio"
										id="customRadioInline2"
										name="customRadioInline1"
										class="custom-control-input"
										v-model="accessMethod"
										value="2"
									/>
									<label
										class="custom-control-label"
										for="customRadioInline2"
										>Código de acesso</label
									>
								</div>
							</div>
							<div
								role="group"
								class="form-group"
								v-if="accessMethod == 1"
							>
								<label for="email" class="d-block my-2"
									>Digite seu e-mail no campo abaixo para
									receber seu link de acesso a última edição
									da revista E-Commerce Brasil.</label
								>
								<div class="bv-no-focus-ring">
									<input
										name="email"
										type="email"
										placeholder="E-mail"
										required="required"
										aria-required="true"
										class="my-3 form-control"
										maxlength="191"
										v-model="email"
									/>
								</div>
							</div>
							<div v-if="accessMethod == 2">
								<div>
									Digite o código de acesso enviado a você no
									campo abaixo junto com seus dados para
									receber seu link de acesso a última edição
									da revista E-Commerce Brasil.
								</div>
								<div role="group" class="form-group mt-4">
									<label for="email" class="d-block my-2"
										>Código de acesso</label
									>
									<div class="bv-no-focus-ring">
										<input
											name="code"
											type="text"
											placeholder="ECBR2021"
											required="required"
											aria-required="true"
											class="form-control"
											maxlength="191"
											v-model="code"
										/>
									</div>
								</div>
								<div role="group" class="form-group">
									<label for="email" class="d-block my-2"
										>Seu nome</label
									>
									<div class="bv-no-focus-ring">
										<input
											name="name"
											type="text"
											placeholder="Nome"
											required="required"
											aria-required="true"
											class="form-control"
											maxlength="191"
											v-model="name"
										/>
									</div>
								</div>
								<div role="group" class="form-group">
									<label for="email" class="d-block my-2"
										>Seu e-mail</label
									>
									<div class="bv-no-focus-ring">
										<input
											name="email"
											type="email"
											placeholder="E-mail"
											required="required"
											aria-required="true"
											class="form-control"
											maxlength="191"
											v-model="email"
										/>
									</div>
								</div>
								<div class="custom-control custom-checkbox">
									<iframe
										class="mb-3"
										src="https://central.ecommercebrasil.com.br/pages/politica-de-privacidade"
										style="width: 100%; border: none;"
									></iframe>
									<input
										type="checkbox"
										class="custom-control-input"
										id="customCheck1"
										v-model="isTermsAccepted"
									/>
									<label
										class="custom-control-label"
										for="customCheck1"
										>Aceito os termos de uso e política de
										privacidade.
									</label>
								</div>
							</div>
							<fieldset
								class="form-group"
								v-if="accessMethod != 0"
							>
								<div
									tabindex="-1"
									role="group"
									class="bv-no-focus-ring text-right"
								>
									<button
										type="submit"
										class="btn-login"
										:disabled="
											sending ||
												(accessMethod == 2 &&
													!isTermsAccepted)
										"
									>
										Enviar link
									</button>
								</div>
							</fieldset>
						</form>
					</div>
				</div>
			</div>
		</div>
		<Maintainers />
		<Footer />
	</div>
</template>
<script>
import NewHeader from './NewHeader.vue'
import NewPlanos from './NewPlanos.vue'
import Maintainers from './Maintainers.vue'
import Footer from './Footer.vue'

export default {
	data() {
		return {
			email: '',
			code: '',
			name: '',
			sending: false,
			accessMethod: 1,
			isTermsAccepted: false
		}
	},
	methods: {
		sendEmail(evt) {
			const form = evt.target

			if (!form.checkValidity()) {
				return false
			}

			evt.preventDefault()

			this.sending = true

			fetch(
				this.accessMethod == 1 ? '/check-email.php' : '/check-code.php',
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded'
					},
					body:
						'email=' +
						encodeURIComponent(this.email) +
						'&code=' +
						encodeURIComponent(this.code) +
						'&name=' +
						encodeURIComponent(this.name)
				}
			)
				.then(res => res.json())
				.then(res => {
					this.sending = false

					if (!res.error) {
						this.$swal('Link de acesso enviado com sucesso!')
						return
					}

					this.$swal(
						`Erro ao enviar link de acesso${
							res.message ? ': ' + res.message : ''
						}.`
					)
				})
				.catch(() => {
					this.sending = false

					this.$swal('Erro ao enviar link de acesso.')
				})
		},
		abrirTermo() {
			this.$swal(
				'<iframe class="mb-3" src="https://central.ecommercebrasil.com.br/pages/politica-de-privacidade" style="width: 100%; border: none;"></iframe>'
			)
		}
	},
	components: {
		NewHeader,
		NewPlanos,
		Maintainers,
		Footer
	}
}
</script>
<style scoped>
.login-row {
	margin-top: 20px;
}
#login-revista .conteudo-login {
	display: flex;
	width: 80%;
	margin: 80px auto;
	justify-content: space-between;
}
#login-revista #planos .title-planos {
	display: none !important;
}
#login-revista .section-planos {
	display: flex;
	width: 43%;
	flex-direction: column;
}
#login-revista .section-login-form {
	display: flex;
	width: 43%;
	flex-direction: column;
}
.title-section-login {
	text-transform: uppercase;
	font-weight: bold;
	font-size: 29px;
	text-transform: uppercase;
	font-weight: bold;
	font-size: 29px;
	border-bottom: 2px solid #000;
	line-height: 55px;
}
.bv-no-focus-ring input {
	border: 2px solid #000;
	border-radius: 0px;
}
.form-group .d-block {
	color: #000;
	text-transform: uppercase;
	font-weight: 600;
	margin: 0px 0px 1px 2px !important;
}
.form-group {
	display: flex;
	flex-wrap: wrap;
	margin: 0px 0px 10px 0px;
}
.form-group .form-label {
	width: 100%;
	color: #000;
	text-transform: uppercase;
	font-weight: 600;
	margin: 0px 0px 1px 2px !important;
}
.bv-no-focus-ring {
	width: 100%;
}
.custom-control {
	margin-right: 10px;
}
.btn-login {
	font-size: 14px;
	text-transform: uppercase;
	background-color: #000;
	padding: 10px 40px;
	text-align: center;
	display: block;
	margin-bottom: 25px;
	color: #fff;
	text-decoration: none;
}
.title-login {
	margin-top: 65px;
	background-color: #000;
	color: #fff;
	text-transform: uppercase;
	font-weight: 100;
	font-size: 25px;
	line-height: 88px;
	text-align: center;
}
.termos {
	color: #0075ff;
	text-decoration: underline;
	cursor: pointer;
}
.custom-control-input {
	margin-right: 5px;
}
.custom-checkbox {
	margin-bottom: 10px;
}
@media (max-width: 415px) {
	#login-revista .conteudo-login {
		flex-direction: column;
	}
	#login-revista .section-planos {
		width: 100%;
	}
	#login-revista .section-login-form {
		width: 100%;
		flex-direction: column;
	}
}
</style>
