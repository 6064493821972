export default {
  materia: [
    {
      titulo: "CAPA",
      resumo: "A confeitaria do e-commerce",
      imgAutor: require("../assets/imgs/autores/amanda.png"),
      nomeAutor: "Amanda Lucio e Helena Canhoni",
      cargoAutor: "E-Commerce Brasil",
      conteudo:
        "O fim dos cookies de terceiros está logo ali, e o e-commerce precisa começar a se adaptar para continuar equilibrando o sabor da personalização com a privacidade. De acordo com os especialistas entrevistados para esta edição, é possível sim fazer publicidade personalizada sem os cookies e respeitando as preferências de dados selecionados pelos consumidores. Já adiantamos que essa é uma tendência mundial e que o mercado brasileiro vai ter que entrar com o pé direito na confeitaria do e-commerce.",
      tempLeitura: "2",
      imgMateria: require("../assets/imgs/imgs_81/500x500px-CAPA.png"),
      active: true,
      viewModal: false,
    },
    {
      titulo: "ENTREVISTA",
      imgAutor: require("../assets/imgs/autores/julia.rondinelli.png"),
      nomeAutor: "Júlia Rondinelli",
      resumo: "É hora de se livrar do plástico",
      cargoAutor: "E-Commerce Brasil",
      conteudo:
        "Sim, é possível lavar e condicionar o cabelo com produtos em barra, que não usam plástico e se preocupam com as matérias-primas, e não, seu cabelo não vai cair. Sustentabilidade é um tema recorrente nesta revista (por um bom motivo). Pensando nisso, trouxemos Victor Lichtenberg para um bate-papo. Ele e a sócia Andreia Quercia entenderam que deixar de consumir não é uma opção, mas é possível repensar toda a cadeia de um produto, de sua formação até o descarte, com o mínimo de impacto ambiental possível.  Foi assim que nasceu a B.O.B. (ou, para nós que ainda não somos íntimos dela ainda, Bars over bottles). ",
      tempLeitura: "2",
      imgMateria: require("../assets/imgs/imgs_81/500x500px-ENTREVISTA.png"),
      active: true,
      viewModal: false,
    },
    {
      titulo: "CASE",
      imgAutor: require("../assets/imgs/autores/lucas.kina.jpg"),
      nomeAutor: "Lucas Kina",
      resumo: "Quem nunca sonhou em ser jogador de futebol?",
      cargoAutor: "E-Commerce Brasil",
      conteudo:
        'Conheça a trajetória da Club Calcio del Roque, loja online de artigos esportivos retrô ou times de futebol de ligas alternativas comandada por Henrique Roque há cinco anos. Samuel Rosa e Nando Reis acertaram quando escreveram o verso "Quem nunca sonhou em ser jogador de futebol?", em 1996, presente na canção “É Uma Partida de Futebol”. Rapidamente, nos seus quase quatro minutos de duração, ela transmite um pouco do que é vivenciar, in loco, um jogo de futebol e, mais do que isso, delirar com a possibilidade de ter esse esporte como profissão. Esse "sonho", como diz a faixa, atingiu e continua a impactar uma série de jovens, meninos e meninas, que são amantes do futebol. Quem não conseguiu fugir disso foi Henrique Oliveira Roque, dono da Club Calcio del Roque (@clubcalcio_ no Instagram), loja que une a venda de camisas de times de futebol de todo o mundo com muito conteúdo, curadoria artesanal de produtos, fidelização e contato pessoal com clientes.',
      tempLeitura: "2",
      imgMateria: require("../assets/imgs/imgs_81/500x500px.png"),
      active: true,
      viewModal: false,
    },
  ],
};
