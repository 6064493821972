var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"body-revista",attrs:{"id":"atual"}},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"revistas-materias"},_vm._l((_vm.conteudoMateria),function(conteudo){return _c('div',{key:conteudo.titulo,staticClass:"card-materia",on:{"mouseover":function($event){conteudo.viewModal = true},"mouseleave":function($event){conteudo.viewModal = false}}},[_c('div',[_c('div',{staticClass:"titulo"},[_c('h2',[_vm._v(_vm._s(conteudo.titulo))])]),_c('div',{staticClass:"informacao"},[_c('p',[_vm._v(" "+_vm._s(conteudo.resumo)+" ")])]),_c('div',{staticClass:"info-autor"},[_c('div',{staticClass:"img-autor"},[_c('img',{attrs:{"src":conteudo.imgAutor,"loading":"lazy"}})]),_c('div',{staticClass:"dados-autor"},[_c('h4',[_vm._v("Por "+_vm._s(conteudo.nomeAutor))]),_c('p',[_vm._v(_vm._s(conteudo.cargoAutor))])]),_c('div',{staticClass:"btns-mobile"},[_c('router-link',{staticClass:"modal-preview",attrs:{"to":{
								name: 'materia',
								params: { materia: 'CAPA' }
							}}},[_vm._v(" Ver Preview ")]),_c('router-link',{attrs:{"to":"/login"}},[_vm._v(" Já Sou Assinante ")])],1)])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(conteudo.viewModal),expression:"conteudo.viewModal"}],staticClass:"modal-revista-atual"},[_c('router-link',{staticClass:"modal-preview",attrs:{"to":{
						name: 'materia',
						params: { materia: conteudo.titulo }
					}}},[_c('p',[_vm._v("LER PREVIEW")])]),_c('router-link',{attrs:{"to":"/login"}},[_c('p',{staticClass:"assinante-modal"},[_vm._v(" ler completo, já sou assinante ")])])],1)])}),0)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"secao-btn-mais"},[_c('div',{staticClass:"ver-mais"},[_c('a',{attrs:{"href":"#atual"}},[_c('p',[_vm._v("Ver Mais")]),_c('img',{attrs:{"src":require("./../assets/novas_imgs/seta_baixo.svg"),"loading":"lazy"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"secao-revista-materia"},[_c('div',{staticClass:"titulo-revista-materia"},[_c('h3',[_vm._v("EDIÇÃO 80")]),_c('p',[_vm._v("Matéria de Capa")])]),_c('div',{staticClass:"revista-materia"},[_c('h2',[_vm._v(" Compro, logo existo ")])])])
}]

export { render, staticRenderFns }